'use strict';

/**
 * Remove Iframe for IOS App and decode iframe's src for desktop
 */
function handleIframeForDesktopAndIOSApp() {
    var isIOSApp = $('.storepage').attr('data-is-ios-app');
    var iframesParent = $('.desktop-iframe');

    if (iframesParent.length > 0) {
        if (isIOSApp === 'false') {
            iframesParent.each(function () {
                var $currentIframeParent = $(this);
                var src = decodeURIComponent(window.atob($currentIframeParent.attr('data-cbo')));
                $currentIframeParent.children().attr('src', src);
                iframesParent.addClass('d-none d-sm-block');
            });
        } else if (isIOSApp === 'true') {
            iframesParent.closest('.layout-row').remove();
        }
    }
}

module.exports = {
    handleIframeForDesktopAndIOSApp: handleIframeForDesktopAndIOSApp
};

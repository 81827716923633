'use strict';

var GTM = require('../global/gtm');
var CS = require('../global/contentSquare');

/**
 * Push Pages from PageDesigner Datalayer by PageDesigner storePage
 */
function pageDesignerDatalayer() {
    $(document).ready(function () {
        var folder = $('.storepage').attr('folder');
        var pagetype = '';
        if (folder === window.GTMConstants.ECOMM_PAGE_TYPE_HOMEPAGE || folder === window.GTMConstants.ECOMM_PAGE_TYPE_GUIDE) {
            pagetype = folder;
        } else {
            pagetype = window.GTMConstants.PAGE_CATEGORY_CATEGORY;
        }

        GTM.pushToDataLayer({
            event: window.GTMConstants.EVENT_TYPE_LOAD,
            pagetype: pagetype,
            categorypage: ''
        });

        if (!(CS.isCVarSet(6))) {
            CS.pushCvarUxa(6, 'pagetype', pagetype);
        }
    });
}

module.exports = {
    pageDesignerDatalayer: pageDesignerDatalayer
};
